import Card from "./Card";
import Web from '../img/web-dev.jpg';
import AI from '../img/AI.webp';
import Cloud from '../img/Cloud.webp';
import Testing from '../img/automation.jpg';
import Analytics from '../img/data-analytics.webp';
import DevOps from '../img/devsecops.jpeg';
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4">
                        <Card title="Web Development" img={Web} text="Our specialty is to develop and maintain advanced web applications and we successfully fulfill all web application development requirements, from small to wider ranged projects. To develop and maintain our high quality apps we have expertise in various advanced web development technologies" />
                    </div>
                    <div className="col-md-4">
                    <Card title="Cloud" img={Cloud} text="We provide tailored solutions and an objective evaluation of existing systems. Our services cover strategy development, readiness assessment, and implementation assessment to help ensure a smooth transition into cloud technology such as Azure cloud for fast and secure application deployment.
Leverage all a cloud platform has to offer." />
                    </div>
                    <div className="col-md-4">
                    <Card title="AI" img={AI} text="With a strategic focus on AI research, development, and implementation, we are poised to revolutionize our industry, delivering cutting-edge solutions that exceed expectations and propel us towards sustainable growth and success. By harnessing AI algorithms and machine learning models, we can unlock valuable insights from our data, automate repetitive tasks, and optimize decision-making processes across various business functions." />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Card title="Testing" img={Testing} text="Embracing automation testing at TechWebHorizon is a strategic move towards enhancing our software development process. By leveraging automated tools and scripts, we aim to streamline our testing efforts, improve test coverage, and accelerate the delivery of high-quality software products to our clients. Automation testing will enable us to detect defects early, reduce manual effort, and ensure consistent and reliable test results across different environments." />
                    </div>
                    <div className="col-md-4">
                    <Card title="DevSecOps" img={DevOps} text="Imagine a seamless blend of development, security, and operations working in perfect harmony to deliver secure and high-quality software at lightning speed. That's the power of DevSecOps. By integrating security practices into every phase of the software development lifecycle, we not only streamline processes but also fortify our digital assets against cyber threats. With our DevSecOps approach, your business can innovate faster, reduce risks, and stay ahead of the curve in today's rapidly evolving digital landscape. Let's revolutionize the way you build, deploy, and secure software together" />
                    </div>
                    <div className="col-md-4">
                    <Card title="Data Analytics" img={Analytics} text="Unlock the hidden potential of your data with our cutting-edge Data Analytics solutions. By harnessing the power of advanced analytics, machine learning, and AI, we help you derive actionable insights that drive informed decision-making and fuel business growth. From optimizing operations to predicting customer behavior, our Data Analytics services empower you to stay ahead of the competition in a data-driven world. Let's transform your data into a strategic asset and pave the way for smarter, more efficient business strategies together." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
