import Main from '../img/main.gif';
import Buildings from '../img/hot-buildings.jpg';
import Young from '../img/young-professionals.jpg';
import YoungWork from '../img/young-working.jpg';
import YoungDWork from '../img/young-dark-working.jpg';
function Header() {
  return (
    <header>
      <div className="headerbg" style={{
        backgroundImage: `url(${Buildings})`,
        backgroundRepeat: 'no-repeat', width: '100%', backgroundSize: 'cover'
      }}>
        <div className="aboutCompany">
          <i className="bi bi-quote"></i>
          Place where Technology & Web meets Horizon
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="44.8" fill="currentColor" className="bi bi-quote" viewBox="0 0 16 16">
            <path d="M4 12a1 1 0 0 1-1-1V8.558a1 1 0 0 1 1-1h1.388q0-.527-.062-1.054-.093-.558-.31-.992t-.559-.683q-.34-.279-.868-.279V3q.868 0 1.52.372a3.3 3.3 0 0 1 1.085.992 4.9 4.9 0 0 1 .62 1.458A7.7 7.7 0 0 1 7 7.558V11a1 1 0 0 1-1 1zm6 0a1 1 0 0 1-1-1V8.558a1 1 0 0 1 1-1H11.388q0-.527-.062-1.054-.094-.558-.31-.992-.217-.434-.559-.683-.34-.279-.868-.279V3q.868 0 1.52.372a3.3 3.3 0 0 1 1.085.992 4.9 4.9 0 0 1 .62 1.458A7.7 7.7 0 0 1 13 7.558V11a1 1 0 0 1-1 1z" />
          </svg>

        </div>
      </div>
    </header>
  );
}
export default Header;
