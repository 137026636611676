import team from '../img/team.jpg';
function About() {
  return (
    <>
      <div style={{ marginTop: '15rem', width: '100%', height: '10px' }} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center">
            <img alt="about" src={team}  style={{width: '35rem', height: '20rem', marginTop: '9rem'}} />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2">ABOUT</h2>
            <p className="main-p">
              We're a dedicated team of professionals with extensive expertise in software development, Cloud services, 
              Web development, Automation testing, AI & Data Analytics, with a deep passion for crafting efficient and innovative solutions, we thrive 
              on leveraging cutting-edge technologies to empower businesses and streamline processes. Whether it's building robust 
              web applications, optimizing cloud infrastructure, or implementing seamless automation frameworks, we're committed 
              to delivering excellence in every project we undertake.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
